import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/dist/client/components/render-from-template-context.js");
